<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/services-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Related Services
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg"></th>
						<th class="p-2 light-blue-bg">Asset</th>
						<th class="p-2 light-blue-bg">Type</th>
						<th class="p-2 light-blue-bg">Status</th>
						<th class="p-2 light-blue-bg">Quantity</th>
						<th class="p-2 light-blue-bg">Location</th>
						<th class="p-2 light-blue-bg">Date Added</th>
					</tr>
				</thead>
				<tbody v-if="services.length">
					<tr v-for="(row, index) in services" :key="index">
						<td class="p-2 border-top-light-grey">
							<v-chip v-if="row.type == 1" color="blue darken-4" text-color="white">M</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">S</v-chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="asset_name" label="asset"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="service_type" label="type"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<v-chip v-if="row.status == 1" color="blue darken-4" text-color="white">Started</v-chip>
							<v-chip v-else-if="row.status == 2" color="green" text-color="white">Completed</v-chip>
							<v-chip v-else color="amber darken-4" text-color="white">Scheduled</v-chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="quantity" label="quantity"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="location_name" label="location"></ShowValue>
						</td>
						<td class="p-2 border-top-light-grey">
							<ShowValue
								:object="row"
								object-key="created_at"
								tooltip
								:tooltip-value="added_at"
								label="date added"
							></ShowValue>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="7">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no related service at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			services: [],
		};
	},
	components: {
		ShowValue,
	},
	methods: {
		getRelatedServices() {
			ApiService.query(`${this.type}/${this.typeUuid}/related-services`)
				.then(({ data }) => {
					this.services = data.tbody;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getRelatedServices();
	},
};
</script>
